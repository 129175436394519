<template>
	<div class="p-contract-detail" :class="{ ready: ready }">
		<m-flow type="contract" :id="5"></m-flow>
	</div>
</template>

<script>
import MFlow from '../components/flow.vue'

	export default {
		components: {
			MFlow,
		},
		data () {
			return {
				ready: false,
				loading: false,
			}
		},
		computed: {},
		mounted () {
			this.ready = true
		},
		methods: {},
	}
</script>

<style lang="less">
	.p-contract-detail {
		display: none;

		&.ready {
			display: block;
		}
	}
</style>
