<template>
	<div class="m-flow" :class="{ ready: ready }">
		<div v-if="canStart">
			<el-button type="primary" @click="processStart()">开始</el-button>
		</div>
		<div v-if="currentItem && currentItem.node.type === 'E'">
			<el-button type="primary" @click="processEnd()">结束</el-button>
		</div>
		<div v-if="currentItem && currentItem.node.type === 'A'">
			<el-button type="primary" v-if="nextItem" @click="processAction(nextItem.node_code)">前进到 {{ nextItem.node.name }}</el-button>
		</div>
		<div v-if="currentItem && currentItem.node.type === 'Y'">
			<el-button type="primary" v-if="nextItem" @click="processYes(nextItem.node_code)">{{ yesText }}，前进到 {{ nextItem.node.name }}</el-button>
			<el-button type="primary" v-if="nextItem2" @click="processNo(nextItem2.node_code)">{{ noText }}，退回到 {{ nextItem2.node.name }}</el-button>
		</div>
		<div>
			<div v-if="currentItem">当前流程步骤: {{ currentItem.node.name }}</div>
			<div v-if="nextItem" style="display: flex;">
				<div>
					下一步:
				</div>
				<div>
					<div>{{ nextItem.node.name }}</div>
					<div>或者</div>
					<div v-if="nextItem2">{{ nextItem2.node.name }}</div>
				</div>
			</div>
		</div>
		<table v-if="nodeList.length > 0">
			<tr>
				<th>状态</th>
				<th>序号</th>
				<th>步骤</th>
				<th>执行时间</th>
				<th>执行人</th>
				<th>备注</th>
			</tr>
			<tr v-for="item of nodeList" :key="'' + item.node_code + ':' + item.id">
				<td>
					<span v-if="item.yes_or_no === 'N'" style="color: red;">
						<i class="gg-close-o"></i>
					</span>
					<span v-else :style="{ color: item.state === 2 ? 'green' : ( item.state === 1 ? 'blue' : '#ddd' ) }">
						<i class="gg-check-o"></i>
					</span>
				</td>
				<td v-text="item.node_code"></td>
				<td v-text="item.node.name"></td>
				<td v-text="item.action_at > 0 ? formatDate(item.action_at) : ''"></td>
				<td>
					<div v-if="item.account">
						<span v-text="item.account.display_name"></span>
						( <span v-text="item.account.email"></span> )
					</div>
				</td>
				<td v-text="item.comment"></td>
			</tr>
		</table>
	</div>
</template>

<script>
import API from '../js/api.js'
import { tsToDt } from '../js/tools.js'

	export default {
		components: {},
		props: {
			type: {
				type: String,
				required: true,
			},
			id: {
				type: Number,
				required: true,
			},
			yesText: {
				type: String,
				required: false,
				default: '审核通过',
			},
			noText: {
				type: String,
				required: false,
				default: '不通过',
			},
		},
		data () {
			return {
				ready: false,
				loading: false,
				nodeList: [],
				currentItem: false,
				nextItem: false,
				nextItem2: false, // 审核不通过时
			}
		},
		computed: {
			canStart () {
				if (this.currentItem) return false;
				if (!this.nodeList) return false;
				if (!this.nodeList.length) return false;
				if (this.nodeList[0].node.type !== 'S') return false;
				if (this.nodeList[0].action_at > 0) return false;
				return true;
			}
		},
		async mounted () {
			try {
				await this.loadData()
				this.ready = true
			} catch(e) {
				console.log(e)
			}
		},
		methods: {
			async loadData () {
				this.nodeList = await API.flow.get({ type: this.type, id: this.id })
				this.currentItem = null
				let currentItem = this.nodeList.find(n => n.state === 1)
				if (currentItem) {
					this.currentItem = currentItem
				}
				this.nextItem = null
				if (this.currentItem && this.currentItem.node.next1) {
					let nextItem = this.nodeList.find(n => n.node_code === this.currentItem.node.next1)
					if (nextItem) {
						this.nextItem = nextItem
					}
				}
				this.nextItem2 = null
				if (this.currentItem && this.currentItem.node.next2) {
					let nextItem2 = this.nodeList.find(n => n.node_code === this.currentItem.node.next2)
					if (nextItem2) {
						this.nextItem2 = nextItem2
					}
				}
			},
			processAction (code) {
				this.$confirm('确定？', '下一步', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'info',
				}).then(() => {
					this.processTo(code, '')
				}).catch(() => {
				})
			},
			processYes (code) {
				this.$confirm('确定？', '审核通过', {
					confirmButtonText: '确定',
					cancelButtonText: '暂不操作',
					type: 'info',
				}).then(() => {
					this.processTo(code, '', 'Y')
				}).catch(() => {
				})
			},
			processNo (code) {
				this.$prompt('', '审核不通过', {
					confirmButtonText: '审核不通过',
					cancelButtonText: '暂不操作',
					type: 'info',
				}).then(({ value }) => {
					this.processTo(code, value || '审核不通过', 'N')
				}).catch(() => {
				})
			},
			async processStart () {
				await API.flow.start(this.type, this.id)
				await this.loadData()
			},
			async processEnd () {
				await API.flow.end(this.type, this.id)
				await this.loadData()
			},
			async processTo (code, comment = '', yn = '') {
				await API.flow.to({
					type: this.type,
					id: this.id,
					code,
					comment: comment || '',
					yn,
				})
				await this.loadData()
			},
			formatDate (value) {
				return tsToDt(value)
			}
		},
	}
</script>

<style lang="less">
	.m-flow {
		display: none;

		&.ready {
			display: block;
		}

		th, td {
			padding: 5px;
			--ggs: 0.7;
		}
	}
</style>
